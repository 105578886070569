import axios from "axios";
import ApiConfig from "./apiconfig";
import toast from "react-hot-toast";
// export const postDataHandler = async (endPoint, data) => {
//   try {
//     const res = await axios({
//       method: "POST",
//       url: ApiConfig[endPoint],
//       data: data,
//     });

//     if (res.data.responseCode === 200) {
//       toast.success(res.data.responseMessage);
//       // toast.success(res.message); 
//       return res.data;
//     } else {
//       toast.error(res.data.responseMessage || "An error occurred");
//       return res;
//     }
//   } catch (error) {
//     if (error.response) {
//       return error.response;
//     } else {
//       return error;
//     }
//   }
// };


export const postDataHandler = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      data: data,
    });

    if (res.data.responseCode === 200) {
      toast.success(res.data.responseMessage);
      return res.data;
    } else {
      // Handling the case for non-200 response codes
      toast.error(res.data.responseMessage || "An error occurred");
      return res;
    }
  } catch (error) {
    // Check if the error response exists and display an appropriate message
    if (error.res) {
      const message = error.res.data?.responseMessage || "An unexpected error occurred";
      toast.error(message);
      return error.response;
    } else {
      toast.error("Please Try Again");
      return error;
    }
  }
};

export const postDataHandlerWithToken = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      data: data,
      headers: {
        token: sessionStorage.getItem("adminToken"),
      },
    });
    // console.log("adfadsfgfs",res);

    if (res.data.responseCode === 200) {
      toast.success(res.data.responseMessage);
      return res.data;
    } else {
      toast.error(res.data.responseMessage);
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      return error;
    }
  }
};

export const getDataHandlerWithToken = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "GET",
      url: ApiConfig[endPoint],
      headers: {
        token: sessionStorage.getItem("adminToken"),
      },
      data: data,
    });
    if (res.data.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    // console.log(error);
  }
};

export const getDataHandlerWithTokenAuthSection = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      headers: {
        token: sessionStorage.getItem("adminToken"),
      },
      params: {
        page:1,
        limit:100
      },
    });
    if (res.data.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    // console.log(error);
  }
};

export const putDataHandler = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "PUT",
      url: ApiConfig[endPoint],

      data: data,
    });
    if (res.data.responseCode === 200) {
      return res.data;
    } else {
      return false;
    }
  } catch (error) {
    // console.log(error);
    if (error.response) {
      return error.response;
    }
  }
};
export const putDataHandlerWithToken = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "PUT",
      url: ApiConfig[endPoint],
      headers: {
        token: sessionStorage.getItem("adminToken"),
      },
      data: data,
    });
    if (res.data.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    // console.log(error);
    if (error.response) {
      return error.response;
    }
  }
};

export const deleteDataHandler = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "DELETE",
      url: ApiConfig[endPoint],
      data: data,
    });
    if (res.data.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    // console.log(error);
  }
};

export const patchDataHandler = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "PATCH",
      url: ApiConfig[endPoint],
      data: data,
    });
    if (res.data.responseCode === 200) {
      toast.success(res.data.responseMessage);
      return res.data;
    } else {
      toast.error(res.data.responseMessage);
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
    // console.log(error);
  }
};
export const patchTokenDataHandler = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "PATCH",
      url: ApiConfig[endPoint],
      headers: {
        token: sessionStorage.getItem("adminToken"),
      },
      data: data,
    });
    if (res.data.responseCode === 200) {
      toast.success(res.data.responseMessage);
      return res.data;
    } else {
      toast.error(res.data.responseMessage);
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
    // console.log(error);
  }
};
