import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { AuthContext } from "src/context/Auth";

export default function AuthGuard(props) {
  const { children } = props;
  const type =  localStorage.getItem("userType");
  const auth = useContext(AuthContext);



  // remove the authGaurd from the because of userType
  if (!auth.userLoggedIn  ) {
    return <Redirect to="/" />;
  }

  return <>{children}</>;
}
